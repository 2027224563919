









































import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { useVoucherAPI } from "@/api_connectors/voucher";
import LogoutButton from "@/components/LogoutButton.vue";
import FailedRequest from "@/components/Toast/FailedRequest.vue";
import SuccessRequest from "@/components/Toast/SuccessRequest.vue";
import { useToast } from "@/composables/useToast";
import VerifyEmail from "@/components/Toast/VerifyEmail.vue";
import { useAuth } from "@/api_connectors/auth";
import router from "@/router";
import RedeemVoucher from "@/components/Modal/RedeemVoucher.vue";
import { useProductAPI } from "@/api_connectors/product";
import { useGlobalStorage } from "@/composables/globalStorage";
import { Bundle, ErrorClass, ProductInfo } from "@/types/typeDefinitions";

export default defineComponent({
    name: "VoucherTMF",
    components: { RedeemVoucher, VerifyEmail, SuccessRequest, FailedRequest, LogoutButton },
    props: {
        voucherCode: String
    },
    setup(props) {
        const { getProductForVoucher, verifyVoucherAPI, matchVoucherError } = useVoucherAPI()
        const { isEmailVerified } = useAuth()
        const { showToast } = useToast()
        const { globalStore } = useGlobalStorage()
        const { getBundleForProductId } = useProductAPI()

        const voucherKey = ref<string>(props.voucherCode ?? '')
        const welcome = ref(false)
        const additionalInfo = ref('')

        const bundle = computed(() => {
            return globalStore.bundle
        })

        const verifyVoucher = async (openModal: Function) => {
            const isVerified = await isEmailVerified()

            const voucherValid = await verifyVoucherAPI(voucherKey.value)

            if ((voucherValid as ErrorClass).error) {
                additionalInfo.value = matchVoucherError((voucherValid as ErrorClass).errorMessage)
                return showToast('failed-request')
            }

            const productResponse = await getProductForVoucher(voucherKey.value)

            if (!isVerified?.data.emailVerified) return showToast('verify-email')

            if ((productResponse as ErrorClass).error) {
                additionalInfo.value = matchVoucherError((productResponse as ErrorClass).errorMessage)
                return showToast('failed-request')
            }

            const bundle = getBundleForProductId((productResponse as ProductInfo).productId)
            if (bundle) globalStore.bundle = bundle

            openModal()
        }

        const setAdditionalInfo = (info: string) => {
            additionalInfo.value = info;
        }

        onMounted(async () => {
            welcome.value = router.currentRoute.meta?.welcome ?? false
            if (voucherKey.value !== '') return;
            voucherKey.value = await localStorage.getItem('voucherCode') ?? ''
            localStorage.removeItem('voucherCode');
        })

        return {
            voucherKey,
            bundle,
            welcome,
            additionalInfo,
            Bundle,
            verifyVoucher,
            setAdditionalInfo
        }
    }
})
